<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_tim.course_wise_participant') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col lg="6" sm="12">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="fiscal_year_id"
                  >
                    <template v-slot:label>
                      {{$t('globalTrans.fiscal_year')}}
                    </template>
                    <!-- <b-form-select
                      plain
                      v-model="search.fiscal_year_id"
                      :options="fiscalYearList"
                      id="fiscal_year_id"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select> -->
                      <v-select name="fiscal_year_id"
                        v-model="search.fiscal_year_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= fiscalYearList
                        :placeholder="$t('globalTrans.select')"
                        :filter-by="myFilter"
                      />
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1" >
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="org_id"
                  >
                    <template v-slot:label>
                      {{$t('elearning_config.organization')}}
                    </template>
                    <!-- <b-form-select
                      plain
                      v-model="search.org_id"
                      :options="orgList"
                      id="org_id"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select> -->
                      <v-select name="org_id"
                        v-model="search.org_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= orgList
                        :placeholder="$t('globalTrans.select')"
                        :filter-by="myFilter"
                      />
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_type_id"
                  >
                    <template v-slot:label>
                      {{$t('elearning_config.training_type')}}
                    </template>
                    <!-- <b-form-select
                      plain
                      v-model="search.training_type_id"
                      :options="trainingTypeList"
                      id="training_type_id"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select> -->
                      <v-select name="training_type_id"
                        v-model="search.training_type_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= trainingTypeList
                        :placeholder="$t('globalTrans.select')"
                        :filter-by="myFilter"
                      />
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_category_id"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_category')}}
                      </template>
                      <!-- <b-form-select
                        plain
                        v-model="search.training_category_id"
                        :options="trainingCategoryList"
                        id="training_category_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select> -->
                      <v-select name="training_category_id"
                        v-model="search.training_category_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= trainingCategoryList
                        :placeholder="$t('globalTrans.select')"
                        :filter-by="myFilter"
                      />
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_title_id"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_title')}}
                      </template>
                      <!-- <b-form-select
                        plain
                        v-model="search.training_title_id"
                        :options="trainingTitleList"
                        id="training_title_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select> -->
                      <v-select name="training_title_id"
                        v-model="search.training_title_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= trainingTitleList
                        :placeholder="$t('globalTrans.select')"
                        :filter-by="myFilter"
                      />
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="batch_no"
                  >
                    <template v-slot:label>
                      {{$t('elearning_iabm.batch_no')}}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.batch_no"
                      :options="batchList"
                      id="batch_no"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="circular_memo_no"
                  >
                    <template v-slot:label>
                      {{$t('elearning_iabm.circular_memo_no')}}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.circular_memo_no"
                      :options="circularList"
                      id="circular_memo_no"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col lg="12" sm="12" class="text-right">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
                <!-- <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_category_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_category')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_category_id"
                            :options="trainingCategoryList"
                            id="training_category_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                      >
                      <template v-slot:label>
                        {{$t('elearning_iabm.circular_memo_no')}}
                      </template>
                        <b-form-select
                          plain
                          v-model="search.circular_memo_no"
                          :options="circularList"
                          id="circular_memo_no"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="batch_no"
                        >
                          <template v-slot:label>
                            {{$t('elearning_iabm.batch_no')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="search.batch_no"
                              :options="batchList"
                              id="batch_no"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value=0>{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col lg="12" sm="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row> -->
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('elearning_tim.course_wise_participant') }} {{ $t('globalTrans.list') }}</h4>
                        <!-- <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button-page">
                          <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
                        </b-button> -->
                    </template>
                    <template v-slot:headerAction>
                        <!-- <b-button variant="primary btn_add_new" @click="pdfExport" class="mr-2">
                          <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                        </b-button> -->
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                              <!-- <b-col md="6" class="mb-2 text-right">
                                <button @click="createData()" class="btn btn-primary btn-sm mr-2" :disabled="selectedItem.length > 0 ? false : true">
                                  <i class="fas fa-list"></i> {{ $t('globalTrans.submit') }}
                                </button>
                              </b-col> -->
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                      <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(org_name)="data">
                                        {{ ($i18n.locale=='bn') ? data.item.org_bn : data.item.org}}
                                      </template>
                                      <template v-slot:cell(batch)="data">
                                        {{ ($i18n.locale=='bn') ? data.item.batch_bn : data.item.batch}}
                                      </template>
                                      <!-- <template v-slot:cell(image)="data">
                                          <img v-if="data.item.image" :src="trainingElearningServiceBaseUrl + 'storage/' + data.item.image" style="width: 80px; height: 80px; border-radius: 50% !important;" class="center">
                                        </template>
                                        <template v-slot:cell(mobile)="data">
                                          {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(org_name)="data">
                                          <span v-if="data.item.profession_type != 1">
                                            {{ ($i18n.locale=='bn') ? data.item.professional_other_org_name_bn : data.item.professional_other_org_name }}
                                          </span>
                                          <span v-if="data.item.profession_type == 1">
                                            {{ ($i18n.locale=='bn') ? data.item.org_bn : data.item.org}}
                                          </span>
                                        </template> -->
                                        <!-- <template v-slot:cell(status)="data">
                                          <span class="badge badge-info" v-if="data.item.status == 1">{{$t('globalTrans.save')}}</span>
                                          <span class="badge badge-success" v-else>{{$t('globalTrans.finalSave')}}</span>
                                        </template> -->
                                        <template v-slot:cell(action)="data">
                                          <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-4 @click="edit(data.item)">
                                            <i class="fas fa-eye"></i>
                                          </a>
                                        </template>
                                        <!-- <template #custom-foot="">
                                          <b-tr>
                                            <b-th colspan="10" class="text-right">
                                              <button @click="createData()" class="btn btn-primary btn-sm mr-2" :disabled="selectedItem.length > 0 ? false : true">
                                                <i class="fas fa-list"></i> {{ $t('globalTrans.submit') }}
                                              </button>
                                            </b-th>
                                          </b-tr>
                                        </template> -->
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="''" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button-page">
              <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button>
            <b-button @click="pdfDownload" class="btn-success water-test-pdf-button-down">
              <i class="fas fa-print float-left"></i>{{  $t('globalTrans.download') }}
            </b-button>
            <b-button @click="wordDownload" class="btn-success water-test-pdf-button-word">
              <i class="fas fa-print float-left"></i>{{  $t('globalTrans.download') }} {{  $t('globalTrans.word') }}
            </b-button>
            <Details :item="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { approvedTraineeList, circularList, trainerSortingStore } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'
// import ExportPdf from './export_pdf_details'
import Details from './Details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
        return {
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          search: {
            // org_id: 0,
            coordinator_id: 0,
            training_type_id: null,
            training_category_id: null,
            training_title_id: null,
            circular_memo_no: 0,
            sort_status: 2,
            registration_for: 2,
            fiscal_year_id: 0,
            batch_no: 0
          },
          selectAll: false,
          circularLoading: false,
          trainingCategoryList: [],
          trainingTitleList: [],
          selectedItem: [],
          circularList: [],
          allBatchListData: [],
          batchList: [],
          status: 0,
          trainingTypeList: [],
          editItemId: [],
          myFilter: (option, text, search) => {
                  const temp = search.toLowerCase()
                  return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                  option.text_bn.toLowerCase().indexOf(temp) > -1
              }
        }
    },
    computed: {
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
      },
      // trainingTypeList: function () {
      //   return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      // },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      currentFiscalYearId: function () {
        return this.$store.state.TrainingElearning.currentFiscalYearId
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
              { label: this.$t('globalTrans.fiscal_year'), class: 'text-center' },
              { label: this.$t('globalTrans.organization'), class: 'text-center' },
              { label: this.$t('elearning_config.training_type'), class: 'text-center' },
              { label: this.$t('elearning_config.training_category'), class: 'text-center' },
              { label: this.$t('elearning_config.training_title'), class: 'text-center' },
              { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-center' },
              { label: this.$t('elearning_iabm.batch_no'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
                { key: 'index' },
                { key: 'fiscal_year_bn' },
                { key: 'org_name' },
                { key: 'training_type_bn' },
                { key: 'training_category_bn' },
                { key: 'training_title_bn' },
                { key: 'circular_memo_no' },
                { key: 'batch_bn' },
                { key: 'action' }
              ]
          } else {
              keys = [
                { key: 'index' },
                { key: 'fiscal_year' },
                { key: 'org_name' },
                { key: 'training_type' },
                { key: 'training_category' },
                { key: 'training_title' },
                { key: 'circular_memo_no' },
                { key: 'batch' },
                { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
        // this.search = Object.assign({}, this.search, {
        //     org_id: this.$store.state.dataFilters.orgId,
        //     fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
        // })
        // this.loadData()
        this.search = Object.assign({}, this.search, {
          org_id: this.$store.state.dataFilters.orgId,
          fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
        })
        if (this.$store.state.Auth.activeRoleId !== 1) {
          this.search.org_id = this.$store.state.Auth.authUser.org_id
          if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
            this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
          }
        }
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
      selectedItem: function () {
        if (this.selectedItem.length === this.listData.length) {
          this.selectAll = true
        }
        if (this.selectedItem.length < this.listData.length) {
          this.selectAll = false
        }
      },
      'search.fiscal_year_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.search.circular_memo_no = 0
          this.search.batch_no = 0
          this.getCircularList()
        }
      },
      'search.org_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.search.training_type_id = null
          this.trainingTypeList = this.getTypeList(newValue)
        } else {
          this.trainingTypeList = []
        }
      },
      'search.training_type_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.search.training_category_id = null
          this.trainingCategoryList = this.getCategoryList(newValue)
        } else {
          this.trainingCategoryList = []
        }
      },
      'search.training_category_id': function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.search.training_title_id = null
          this.trainingTitleList = this.getTrainingTitleList(newValue)
        } else {
          this.trainingTitleList = []
        }
      },
      'search.training_title_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.search.circular_memo_no = 0
          this.search.batch_no = 0
          this.getCircularList()
        }
      },
      'search.circular_memo_no': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
          if (typeof allBatchListData !== 'undefined') {
            this.search.batch_no = allBatchListData.batch_no
          }
        } else {
          this.allBatchListData = []
        }
      },
      'search.batch_no': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
          if (typeof allBatchListData !== 'undefined') {
            this.search.circular_memo_no = allBatchListData.circular_memo_no
          }
        } else {
          this.allBatchListData = []
        }
      }
      // 'search.training_title_id': function (newVal, oldVal) {
      //   if (newVal !== oldVal) {
      //     this.getCircularList()
      //   }
      // },
      // 'search.training_type_id': function (newValue) {
      //   this.trainingCategoryList = this.getCategoryList(newValue)
      // },
      // 'search.training_category_id': function (newValue) {
      //   this.trainingTitleList = this.getTrainingTitleList(newValue)
      // },
      // 'search.circular_memo_no': function (newVal, oldVal) {
      //   if (newVal !== oldVal) {
      //     const batchData = this.allBatchListData.filter(allBatchList => String(allBatchList.circular_memo_no) === String(newVal))
      //     const batchList = []
      //     batchData.map((obj, index) => {
      //       if (obj.batch_no) {
      //         const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
      //         if (typeof isThere !== 'undefined') {
      //           batchList.push(isThere)
      //         }
      //       }
      //     })
      //     this.batchList = batchList
      //     if (this.batchList.length === 1) {
      //       this.search.batch_no = 1
      //     }
      //   }
      // }
    },
    methods: {
      async createData () {
        const obj = {
          selected_itm: this.selectedItem
        }
        this.load = true
        let result = null
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainerSortingStore, obj)
        if (result.success) {
          // this.$socket.emit('send-notification', result.notification)
          this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          // this.$refs.form.reset()
          this.load = true
          this.$router.push('/training-e-learning-service/tim/trainee-selection')
        }
        this.load = false
      },
      edit (item) {
        this.editItemId = item
      },
      checkAll () {
        if (this.selectAll) {
          this.selectedItem.splice(0, this.selectedItem.length)
          this.listData.filter(el => (el.sort_status === 1)).forEach(element => this.selectedItem.push(element.id))
        } else {
          this.selectedItem.splice(0, this.selectedItem.length)
        }
      },
      // async getCircularList () {
      //   this.circularLoading = true
      //   const serchData = {
      //     fiscal_year_id: this.search.fiscal_year_id,
      //     training_type_id: this.search.training_type_id,
      //     training_category_id: this.search.training_category_id,
      //     training_title_id: this.search.training_title_id
      //   }
      //   const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
      //   if (!result.success) {
      //     this.circularList = []
      //     this.allBatchListData = []
      //   } else {
      //     this.allBatchListData = result.batchList
      //     const listObject = result.data
      //     const tmpList = listObject.map((obj, index) => {
      //       if (this.$i18n.locale === 'bn') {
      //         return { value: obj.circular_memo_no, text: obj.circular_memo_no }
      //       } else {
      //         return { value: obj.circular_memo_no, text: obj.circular_memo_no }
      //       }
      //     })
      //     this.circularList = tmpList
      //   }
      //   this.circularLoading = false
      // },
      getTypeList (orgId) {
        if (this.search.org_id) {
          const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
          if (orgId) {
            return type.filter(item => item.org_id === parseInt(orgId))
          }
          return type
        }
      },
      batchInfo () {
        const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.search.training_title_id))
        const batchList = []
        batchData.map((obj, index) => {
          if (obj.batch_no) {
            const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
            if (typeof isThere !== 'undefined') {
              batchList.push(isThere)
            }
          }
        })
        this.batchList = batchList
        if (this.batchList.length === 1) {
          this.search.batch_no = 1
        }
      },
      circluarList () {
        const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.search.training_title_id))
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          }
        })
        this.circularList = tmpList
      },
      async getCircularList () {
        if (this.search.fiscal_year_id && this.search.training_title_id) {
          this.circularLoading = true
          const serchData = {
            fiscal_year_id: this.search.fiscal_year_id,
            training_type_id: this.search.training_type_id,
            training_category_id: this.search.training_category_id,
            training_title_id: this.search.training_title_id,
            coordinator_id: this.search.coordinator_id
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
          if (!result.success) {
          } else {
            this.allBatchListData = result.batchList
            this.batchInfo()
            this.circluarList()
          }
          this.circularLoading = false
        }
      },
      finalSave (item) {
        // this.toggleStatusCustom2(trainingElearningServiceBaseUrl, courseManagmentFinalSave, item)
      },
      toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
            this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      getCategoryList (typeId) {
        if (this.search.training_type_id) {
          const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
          if (typeId) {
            return trainingCategoryList.filter(item => item.training_type_id === typeId)
          }
          return trainingCategoryList
        }
      },
      getTrainingTitleList (categoryId) {
        if (this.search.training_category_id) {
          const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
          if (categoryId) {
            return trainingTitleList.filter(item => item.training_category_id === categoryId)
          }
          return trainingTitleList
        }
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      remove (item) {
        //   this.changeStatus(trainingElearningServiceBaseUrl, courseManagmentToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
      },
      loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, approvedTraineeList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }
          const batchObj = this.allBatchList.find(doc => doc.value === parseInt(item.batch_no))
          const batchData = {}
          if (typeof batchObj !== 'undefined') {
            batchData.batch = batchObj.text_en
            batchData.batch_bn = batchObj.text_bn
          } else {
            batchData.batch = ''
            batchData.batch_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          return Object.assign({}, item, batchData, orgData, fiscalYearData, trainingTitleData, trainingTypeData, trainingCategoryData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      },
      pdfDownload () {
        this.$refs.details.pdfDownload()
      },
      wordDownload () {
        this.$refs.details.wordDownload()
      }
      // pdfExport () {
      //   const reportTitle = this.$i18n.locale === 'en' ? 'Approved Trainee List' : 'অনুমোদিত প্রশিক্ষণার্থীর তালিকা'
      //   ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/fertilizer/config/report-heading/detail', 12, reportTitle, this)
      // }
    }
}
</script>
<style scoped>
.water-test-pdf-button-page {
  position: absolute !important;
  right: 150px !important;
  z-index: 999 !important;
  top: -39px !important;
  background: white !important;
  color: #66cc99 !important;
}
.water-test-pdf-button-down {
  position: absolute !important;
  right: 40px !important;
  z-index: 999 !important;
  top: -39px !important;
  background: white !important;
  color: #66cc99 !important;
}
.water-test-pdf-button-word {
  position: absolute !important;
  right: 230px !important;
  z-index: 999 !important;
  top: -39px !important;
  background: white !important;
  color: #66cc99 !important;
}
</style>
